var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file-wrapper",style:({
    justifyContent: _vm.botMessage ? 'center' : _vm.isUserMessage ? 'flex-end' : 'flex-start',
  })},[_c('div',{staticClass:"message-wrapper",class:{
      'message-bubble-left': !_vm.isUserMessage && !_vm.imageContent,
      'message-bubble-right': _vm.isUserMessage && !_vm.imageContent,
      'message-image': _vm.imageContent,
    },style:(!_vm.imageContent ? _vm.bubbleStyle : {})},[(_vm.message.data.content[0].images || _vm.message.data.content[0].image)?_c('div',_vm._l((_vm.fileUrls),function(item,index){return _c('el-card',{key:index,staticStyle:{"height":"fit-content"},attrs:{"body-style":{
          padding: '0',
          height: 'fit-content',
          display: 'block',
        }}},[_c('a',{staticClass:"has-cursor-pointer",on:{"click":function($event){return _vm.$emit('setLightBoxImage', item)}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":item}})])])}),1):(_vm.message.data.content[0].files)?_c('div',[_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:(_vm.$options.viewerOptions),expression:"$options.viewerOptions"}],staticStyle:{"cursor":"pointer"},style:(_vm.bubbleStyle)},_vm._l((_vm.fileUrls),function(item,index){return _c('div',{key:index},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":item,"target":"_blank"}},[_c('i',{staticClass:"el-icon-document",staticStyle:{"font-size":"40px"}}),_vm._v(" "+_vm._s(_vm.fileNameFromURL(item))+" ")])])}),0)]):(_vm.message.data.content[0].videos)?_c('div',{directives:[{name:"viewer",rawName:"v-viewer",value:(_vm.$options.viewerOptions),expression:"$options.viewerOptions"}],staticStyle:{"cursor":"pointer"},style:(_vm.bubbleStyle)},_vm._l((_vm.fileUrls),function(item,index){return _c('div',{key:index},[(_vm.isNative)?_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.openFile(item)}}},[_c('i',{staticClass:"el-icon-video-camera",staticStyle:{"font-size":"40px"}}),_vm._v(" "+_vm._s(_vm.fileNameFromURL(item))+" ")]):_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":item,"target":"_blank"}},[_c('i',{staticClass:"el-icon-video-camera",staticStyle:{"font-size":"40px"}}),_vm._v(" "+_vm._s(_vm.fileNameFromURL(item))+" ")])])}),0):_vm._e(),(!_vm.botMessage || (_vm.botMessage && !_vm.imageContent))?_c('div',{staticStyle:{"text-align":"right"}},[_c('i',{staticStyle:{"font-size":"9px !important"},domProps:{"innerHTML":_vm._s(_vm.timestamp(_vm.message))}})]):_vm._e()]),_c('ResendMessageButton',{attrs:{"isVisible":_vm.isUserMessage && !_vm.isMessageSent,"resendMessage":_vm.resendMessage,"isDisabled":_vm.isResendButtonDisabled,"errorMessage":_vm.message.errorMessage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }