import { initCobrowse } from "@/cobrowse";
import parseJWTPayload from "@/helpers/parseJWTPayload";
import { splitQuickReplies } from "@/helpers/sanitizer";
import { getLastActivityDate, NOT_VALID_RESPONSE } from "@/helpers/trendingTopics";
import { FAILED_MESSAGE_ERROR_TEXT, handleWebsocketError } from "@/utils";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import { AxiosPromise } from "axios";
import cuid from "cuid";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { MessageBox } from "element-ui";
import jwt from "jsonwebtoken";
import _ from "lodash-es";
import Push from "push.js";
import io from "socket.io-client";
import Vue from "vue";
import { ActionTree } from "vuex";
import axios, {
  getNewToken,
  isTokenConfigured,
  setTokenToAxiosAuthorizationHeader,
} from "../helpers/axios";
import getParamsFromUrl from "../helpers/getParamsFromUrl";
import { Message, RootState } from "./types";

dayjs.extend(advancedFormat);

function releaseId() {
  return `${process.env.NAME}@${process.env.VERSION}`;
}

export const actions: ActionTree<RootState, RootState> = {
  async START({ state, dispatch, commit }) {
    if (state.settings.apps.bot) {
      dispatch("REQUEST_NOTIFICATION_PERMISSION");
      const url = _.get(window, "kr_settings.getToken.url");
      const method = _.get(window, "kr_settings.getToken.method");
      if (!state.greeted) {
        commit("SET_GREETED", true);

        if (state.settings.cobrowse?.enabled) {
          await dispatch("FETCH_COBROWSE_STATUS");
        }

        const lastSeen = dayjs(localStorage.getItem("_krlastseen") || undefined);
        const now = dayjs();

        const isSameDay = now.isSame(lastSeen, "day");

        const hasJWTConfiguration = isTokenConfigured(url, method) || state.user.JWT;
        let shouldSendConversationResume = false;
        const params = new URLSearchParams(location.search);
        const shouldSendStartMessage = params.get("start") !== "0";
        let isNewSession: boolean = false;
        let currentSession: any;

        if (hasJWTConfiguration) {
          //JWT flow
          if (state.user) {
            if (state.settings.resume !== "NEVER") {
              await dispatch("REGISTER");
              // setting for jwt users, it should check current session first before fetch history
              currentSession = await dispatch("GET_CURRENT_SESSION");
              const isNewSession = _.get(currentSession, "data.isNewSession");
              const isContinousSession = _.get(currentSession, "data.isContinousSession");
              if (!isNewSession || isContinousSession) {
                await dispatch("FETCH_HISTORY");
                commit("SET_NEW_USER", false);
              }
            }
          }
        } else {
          //Normal flow
          if (!state.uid) {
            await dispatch("REGISTER");
            await dispatch("LOGIN");
          } else {
            commit("SET_NEW_USER", false);
          }
        }

        const fetchHistoryByUser = state.settings.chatHistoryMode === "USER";
        switch (state.settings.resume) {
          case "ALWAYS":
            await dispatch("FETCH_HISTORY");
            if (!isSameDay) {
              shouldSendConversationResume = true;
            }
            break;

          case "SESSION": {
            // checking for non jwt users
            if (!currentSession) {
              currentSession = await dispatch("GET_CURRENT_SESSION");
              isNewSession = _.get(currentSession, "data.isNewSession");
            }
            if (!isNewSession) {
              if (!state.user || !hasJWTConfiguration) {
                if (!isSameDay) {
                  shouldSendConversationResume = true;
                }
                await dispatch("FETCH_HISTORY");
              }
            } else {
              if (fetchHistoryByUser) {
                await dispatch("FETCH_HISTORY");
              }
              localStorage.setItem("keyreply-lastOpenStatus", "false");
              await dispatch("REGISTER");
              await dispatch("LOGIN");
            }
            break;
          }

          case "NEVER":
            commit("TOGGLE_LIVECHAT_BANNER", false);
            await dispatch("REGISTER");
            await dispatch("LOGIN");
            if (fetchHistoryByUser) {
              commit("SET_NEW_USER", false);
              await dispatch("FETCH_HISTORY");
            }
            break;
          default:
            await dispatch("LOGIN");
        }

        await dispatch("LISTEN");
        await dispatch("FETCH_LIVECHAT_STATUS");
        await dispatch("SEND_START_MESSAGE", {
          shouldSendConversationResume,
          shouldSendStartMessage,
          hasJWTConfiguration,
          isSameDay,
          isNewSession,
        });
      }
    }
  },
  async REQUEST_NOTIFICATION_PERMISSION({ state, dispatch, commit }) {
    const params = getParamsFromUrl();
    const mobileMode = params.get("mode") === "mobile";

    if (state.settings.enablePushNotification && !Push.Permission.has() && !mobileMode) {
      MessageBox.confirm(
        "Allow Notification Permission to receive message when you change tab",
        "Permission Request",
        {
          confirmButtonText: "Allow",
          cancelButtonText: "Deny",
          type: "info",
          center: true,
        }
      )
        .then(() => {
          Push.Permission.request(
            () => {
              commit("SET_NOTIFICATION_PERMISSION", true);
            },
            () => {
              commit("SET_NOTIFICATION_PERMISSION", false);
            }
          );
        })
        .catch(() => {
          commit("SET_NOTIFICATION_PERMISSION", false);
        });
    } else {
      commit("SET_NOTIFICATION_PERMISSION", true);
    }
  },
  // TODO: deprecated soon
  SEND_START_MESSAGE_JWT({ state, dispatch }) {
    let startMessage = null;
    if (state.settings.start && state.settings.start.event && state.settings.start.data) {
      startMessage = state.settings.start;
    } else {
      startMessage = {
        event: "goto",
        data: "conversation_start",
      };
    }

    if (startMessage) {
      return dispatch("SEND_POSTBACK", startMessage);
    }
  },
  // FIXME: account for failedRequests in state
  async SET_JWT_RESEND_REQUEST({ state, commit }, { JWT }) {
    setTokenToAxiosAuthorizationHeader(JWT);
    if (state.failedRequests.length > 0) {
      const allRunningRequests: AxiosPromise<any>[] = [];
      state.failedRequests.forEach(async (failedRequest) => {
        Object.assign(
          failedRequest,
          { headers: { Authorization: `Bearer ${JWT}` } },
          { data: JSON.parse(failedRequest.data) }
        );
        allRunningRequests.push(axios({ ...failedRequest }));
      });
      await Promise.all(allRunningRequests);
      commit("CLEAR_EXPIRED_REQUESTS");
    }
  },
  SEND_START_MESSAGE(
    { state, dispatch },
    payload: {
      shouldSendConversationResume?: boolean;
      shouldSendStartMessage?: boolean;
      hasJWTConfiguration?: boolean;
      isSameDay?: boolean;
      isNewSession?: boolean;
    } = {}
  ) {
    const {
      shouldSendConversationResume,
      shouldSendStartMessage = true,
      hasJWTConfiguration,
      isSameDay,
      isNewSession,
    } = payload;
    const params = getParamsFromUrl();
    const tokenProvided = params.get("token");

    let startMessage = null;
    // Choose start message
    if (state.simulateUser || state.newUser || hasJWTConfiguration || tokenProvided) {
      if (state.settings.start && state.settings.start.event && state.settings.start.data) {
        // Custom entry point
        startMessage = state.settings.start;

        // for handling AIA custom entry ismart mobile app
        const customEntryAIA = state.settings.start.event === "get_agent_achievement_code";
        const inMobileMode = params.get("mode") === "mobile";
        if (customEntryAIA) {
          startMessage.data =
            (shouldSendStartMessage && !isSameDay) || isNewSession || !inMobileMode ? "1" : "0";
        }
      } else if (shouldSendStartMessage || isNewSession) {
        startMessage = {
          event: "goto",
          data: "conversation_start",
        };
      }
    } else if (shouldSendConversationResume) {
      startMessage = {
        event: "goto",
        data: "conversation_resume",
      };
    }

    if (startMessage) {
      return dispatch("SEND_POSTBACK", startMessage);
    }
  },

  LISTEN({ state, dispatch, commit }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!state.uid || !state.server) {
        reject();
      }
      /*
        Extract BASE_URL variable for Kubernetes deployment since
        server URL will be inserted via config.js
      */
      const baseURL = _.get(window, "appConfig.BASE_URL", state.server);
      const signalServer = _.get(window, "appConfig.SIGNAL_SERVER_URL", state.server) || baseURL;
      const disablePollingAppConfig = _.get(window, "appConfig.DISABLE_POLLING");
      const disablePollingKrSettings = _.get(window, "kr_settings.DISABLE_POLLING");
      const disablePollingDashboardSettings = _.get(state, "settings.DISABLE_POLLING");
      const disablePolling =
        disablePollingKrSettings || disablePollingAppConfig || disablePollingDashboardSettings;
      const socketUri = new URL("/webchat", signalServer).href;
      const socketPath = new URL("socket.io", signalServer).pathname;

      state.socket = io(socketUri, {
        path: socketPath,
        withCredentials: false,
        transports: disablePolling ? ["websocket"] : ["polling", "websocket"],
      });

      const socket = state.socket;

      let retryCount = 0;
      let firstSocketConnect = true;
      state.socket.on("disconnect", (reason: string) => {
        commit("UPDATE_SOCKET_CONNECTION_STATUS", false);
        dispatch("TOGGLE_TYPING_INDICATOR", false);
        // console.log("Socket disconnected.");
        /*
        io server disconnect is the socket.io hard coded value
        */
        if (reason === "io server disconnect" && retryCount < 10) {
          // the disconnection was initiated by the server, you need to reconnect manually
          // console.log("disconnected by server. attempting to reconnect");
          socket.connect();
          retryCount++;
        }
      });
      state.socket.on("connect", () => {
        socket.emit("uid", { uid: state.uid });
        retryCount = 0;
        if (!firstSocketConnect && state.settings.liveChatStatus) {
          // dispatch("FETCH_HISTORY", { isInliveChat: true });
          commit("SET_SESSION_BUTTON_READY");
        } else {
          firstSocketConnect = false;
        }
        commit("UPDATE_SOCKET_CONNECTION_STATUS", true);
        dispatch("TOGGLE_TYPING_INDICATOR", false);
        dispatch("FETCH_LIVECHAT_STATUS");
        resolve();
      });

      state.socket.on("action", async (action: { id: string; payload: any }) => {
        await dispatch(action.id, action.payload);
      });

      state.socket.on("handover_start", (isStarted: boolean) => {
        commit("TOGGLE_LIVECHAT_BANNER", isStarted);
      });

      state.socket.on("message", (payload: any) => {
        const isMessageExist = state.messages.find((message) => {
          const hasSameId = message.id === payload.id;
          return hasSameId;
        });

        if (state.lightbox) {
          commit("CLOSE_DIALOG");
        }

        commit("SET_SENDING_POSTBACK", false);

        // Message already exist in state
        if (!isMessageExist) {
          // Popup notification for text messages (ignore menu)
          if (state.chatWindow === false) {
            if (payload.data.content.length === 1) {
              const text = _.get(payload, "data.content[0].text");
              if (text && typeof text === "string") {
                state.messagePreview = text;
                state.showMessagePreview = true;
              }
            }
          }

          const autopilot = _.get(payload, "data.content[0].options.autopilot");
          if (autopilot) {
            // Navigate automatically
            if (location.href != autopilot) {
              location.href = autopilot;
            }
          }

          commit("RECEIVE_MESSAGE", payload);
        }
      });

      state.socket.on("error", (error: Error) => {
        handleWebsocketError(error);
        reject(error);
      });
    });
  },
  async FETCH_HISTORY({ state, commit, dispatch }, params) {
    const limit = 10;
    const offset = state.messages.length;
    if (state.uid && state.token) {
      await axios
        .post(new URL("api/webchat_history", state.server).href, {
          page_id: state.page_id || location.hostname,
          user_id: state.uid,
          session_id: localStorage.getItem("_krsessionid"),
          token: state.token,
          limit,
          offset,
        })
        .then((response) => {
          if (response && response.data && !_.isEmpty(response.data.messages)) {
            const messagesToAdd: Message[] = _.clone(response.data.messages);
            const defaultLanguage = state.settings.defaultLanguage || "ms";
            const currentLanguage = localStorage.getItem("currentLanguage") || defaultLanguage;
            localStorage.setItem("currentLanguage", currentLanguage);
            commit("SET_CURRENT_LANGUAGE", currentLanguage);
            messagesToAdd.forEach((message) => {
              const isMessageExist = state.messages.find((stateMessage) => {
                const hasSameId = message.id === stateMessage.id;
                return hasSameId;
              });

              if (!isMessageExist) {
                if (state.settings?.feedbackCollectionEnabled) {
                  const quickReplies = _.clone(message.data.quickReplies);
                  const [definedQuickReplies] = splitQuickReplies(quickReplies);
                  message.data.quickReplies = definedQuickReplies;
                }
                message.timestamp = new Date(message.timestamp);
                state.messages.unshift(message);
              }
            });
          }

          if (_.get(response, "data.messages.length", 0) < limit) {
            commit("SET_ALL_PAST_MESSAGES_FETCHED");
          }
          commit("SET_SESSION_BUTTON_READY");
        })
        .catch(async (error) => {
          await dispatch("REGISTER");
          await dispatch("LOGIN");
          Sentry.captureException(error);
        });
    }
  },
  async REGISTER({ state, dispatch, commit }) {
    return await axios
      .post(new URL("api/webchat_new_user", state.server).href, {
        // keep as location.hostname to retain partition if not using JWT
        // this is to allow same JWT user across endpoints to retain session
        page_id: location.hostname,
        initial_state: state.settings.initialState,
      })
      .then((response) => {
        if (!response || !response.data) {
          //FIXME(Brian): there some cases the response return is empty
          //we should aware and handle negative effect
          return;
        }

        localStorage.setItem("_kruid", response.data.uid);
        commit("SET_USER_ID", response.data.uid);
        commit("SET_PAGE_ID", response.data.page_id);
        commit("SET_TOKEN", response.data.token);

        Sentry.setTag("UID", response.data.uid);
        Sentry.setUser({ id: response.data.uid });

        if (!state.user.JWT) {
          localStorage.setItem("_krtoken", response.data.token);
        }
      })
      .catch((e) => {
        throw e;
      });
    // return dispatch("LOGIN");
  },
  async GET_CURRENT_SESSION({ state }) {
    return await axios
      .post(new URL("api/webchat_get_current_session", state.server).href, {
        page_id: state.page_id || location.hostname,
        user_id: state.uid,
        session_length: state.settings.session_length,
        resume_session: state.settings.resume,
      })
      .then((response) => response);
  },
  async LOGIN({ state, dispatch, commit }) {
    if (!state.uid || !state.server) {
      return;
    }
    // FIXME: @Dhoni check this action has purpose or not
    if (state.user) {
      await dispatch("FETCH_JWT", state.user);
    }

    await axios
      .post(new URL("api/webchat_new_session", state.server).href, {
        page_id: state.page_id || location.hostname,
        user_id: state.uid,
        session_length: state.settings.session_length,
        resume_session: state.settings.resume,
        page_url: location.href,
      })
      .then((response) => {
        commit("SET_NEW_USER");
        commit("SET_SESSION_EXPIRED", false);

        commit("SET_SESSION_BUTTON_READY");
        // get sessionid and store
        // sessionStorage.setItem("_krsessionid", response.data.sessionid);
        localStorage.setItem("_krsessionid", response.data.sessionid);
        Sentry.setTag("SESSION_ID", response.data.sessionid);
      })
      .catch((e) => {
        throw e;
      });
  },
  async END_SESSION({ state, dispatch }) {
    const endMessages = {
      event: "conversation_end_session",
      data: null,
    };
    await dispatch("SEND_POSTBACK", endMessages);

    if (!_.isEmpty(state.server)) {
      await axios.post(new URL("api/webchat_end_session", state.server).href, {
        page_id: state.page_id || location.hostname,
        user_id: state.uid,
      });
    }
  },
  async CLEAR_CLONE_STATE({ state, dispatch }) {
    return await axios.post(new URL("api/webchat_clear_clone_state", state.server).href, {
      page_id: state.page_id || location.hostname,
      user_id: state.uid,
    });
  },
  async LOGOUT({ state, commit }) {
    if (!state.sessionEnded && !_.isEmpty(state.server)) {
      await axios.post(new URL("api/webchat_end_session", state.server).href, {
        page_id: state.page_id || location.hostname,
        user_id: state.uid,
      });
    }

    // commit("SET_USER_ID", "");
    commit("SET_GREETED", false);
    commit("CLEAR_MESSAGES");

    // FIXME(Brian): remove commented lines?
    // commit("SET_USER_ID", "");

    if (state.socket) {
      state.socket.disconnect();
    }

    // localStorage.removeItem("_kruid");
    // localStorage.removeItem("_krtoken");
    localStorage.removeItem("showTextbar");
  },
  async CLEAR_CACHE({ commit, dispatch }) {
    commit("SET_USER_ID", "");
    localStorage.removeItem("_kruid");
    localStorage.removeItem("_krtoken");
  },
  FETCH_LIVECHAT_STATUS({ state, dispatch, commit }) {
    return axios
      .post(new URL("api/webchat_livechat_check", state.server).href, {
        sender: {
          id: state.uid,
        },
        recipient: {
          id: state.page_id || location.hostname,
        },
      })
      .then((result) => {
        const isTalkingToAgent = _.get(result, "data.talkingToAgent");
        commit("TOGGLE_LIVECHAT_BANNER", isTalkingToAgent);
      });
  },
  async FETCH_AGENT_AVAILABILITY({ state }) {
    const result: any = await axios.get(
      new URL("api/webchat_agent_availability_check", state.server).href
    );
    const agentAvailable = _.get(result, "data.agentAvailable", false);
    return agentAvailable;
  },
  async FETCH_TRENDING_TOPICS({ state, dispatch, commit }) {
    const result: any = await axios.get(new URL("api/webchat_get_trending", state.server).href);
    commit("SET_TRENDING_TOPICS", result.data);
  },
  async CLICK_INTENT({ state }, payload) {
    const userIp = state.uid;
    const lastActivity = getLastActivityDate();
    const intentId = JSON.stringify(payload);
    return axios.post(new URL("api/webchat_livechat_click_intent", state.server).href, {
      intentId,
      userIp,
      lastActivity,
    });
  },
  async FETCH_CONFIG({ state, dispatch, commit }) {
    const params = new URLSearchParams(location.search);
    const userSource = params.get("user-source") || state.user?.source;
    const jwt = state.user?.JWT; // to notify it is post login scenario if contains jwt
    const newParams = new URLSearchParams();

    if (userSource) {
      newParams.set("user-source", userSource);
    }
    if (jwt) {
      newParams.set("post-login", "1");
    }

    const sourceParam = newParams.toString();

    const configResult: any = await axios.get(
      new URL("api/webchat_config" + (sourceParam ? "?" : "") + sourceParam, state.server).href
    );
    // set default language
    const currentLanguage = localStorage.getItem("currentLanguage") || "ms";
    localStorage.setItem("currentLanguage", currentLanguage);
    commit("SET_CURRENT_LANGUAGE", currentLanguage);
    // set trending topic setting
    const {
      data: { trendingTopicBanner, trendingTopicTitle, defaultLanguage },
    } = configResult;
    if (defaultLanguage) {
      localStorage.setItem("currentLanguage", defaultLanguage);
      commit("SET_CURRENT_LANGUAGE", defaultLanguage);
    }
    commit("SET_COLOR_TITLE_TRENING", trendingTopicTitle);
    commit("SET_COLOR_BANNER_TRENING", trendingTopicBanner);

    const exclude = _.get(configResult, "data.exclude", "");
    if (exclude && exclude.indexOf(location.pathname) > -1) {
      return;
    } else {
      const displayLauncherOnAgentAvailability = _.get(
        configResult,
        "data.displayLauncherOnAgentAvailability",
        { enabled: false }
      );

      const shouldCheckMaxUsersLimit = _.get(configResult, "data.shouldCheckMaxUsersLimit", false);

      // add check for settings
      if (displayLauncherOnAgentAvailability.enabled) {
        const DELAY_IN_MS = displayLauncherOnAgentAvailability.delay * 1000;
        setTimeout(async () => {
          const agentAvailable = await dispatch("FETCH_AGENT_AVAILABILITY");
          if (agentAvailable) {
            commit("SET_DISPLAY_LAUNCHER", true);
          } else {
            const checkAgentAvailabilityInterval = setInterval(async () => {
              const agentAvailable = await dispatch("FETCH_AGENT_AVAILABILITY");
              if (agentAvailable) {
                commit("SET_DISPLAY_LAUNCHER", true);
                clearInterval(checkAgentAvailabilityInterval);
              }
            }, 20000);
          }
        }, DELAY_IN_MS);
      } else if (shouldCheckMaxUsersLimit) {
        const webchatUsersNotAtMaxLimit = await dispatch("CHECK_WEBCHAT_MAX_LIMIT");
        if (!webchatUsersNotAtMaxLimit) {
          commit("SET_WEBCHAT_USERS_IS_AT_MAX_LIMIT", true);
          commit("UPDATE_SHOW_PREVIEW_BUBBLE", { status: true });
        }
        commit("SET_DISPLAY_LAUNCHER", true);
      } else {
        commit("SET_DISPLAY_LAUNCHER", true);
      }
      return dispatch("RELOAD", configResult.data);
    }
  },

  async CHECK_WEBCHAT_MAX_LIMIT({ state }) {
    try {
      const webchatNotAvailable = await axios.get(
        new URL("api/webchat_check_max_limit", state.server).href
      );
      return _.get(webchatNotAvailable, "data.status", false);
    } catch (error) {
      console.log(error);
      return false;
    }
  },

  async INITIALIZE({ state, dispatch }, initialSettings) {
    if (initialSettings) {
      // Overwrite initial settings
      window.kr_settings = initialSettings;
    }

    dispatch("FETCH_ENDPOINT");
  },
  async FETCH_JWT({ state, dispatch, commit }, { user }) {
    const params = getParamsFromUrl();
    const token = params.get("token") || "";

    if (user) {
      if (!user.JWT) {
        if (typeof window.kr_settings.getToken === "function") {
          // URL, METHOD
          const JWT = await window.kr_settings.getToken();
          user.JWT = JWT;
        }

        const url = _.get(window, "kr_settings.getToken.url");
        const method = _.get(window, "kr_settings.getToken.method");
        const accessTokenPath = _.get(window, "kr_settings.getToken.accessTokenPath");

        if (isTokenConfigured(url, method)) {
          if (!token) {
            try {
              const JWT = await getNewToken(url, method, accessTokenPath);
              user.JWT = JWT;
            } catch (err) {
              commit("JWT_MESSAGE", {
                title: "Error Get Token",
                text: "Error getting token from given url. Please check the getToken.url.",
              });

              return Promise.reject(err);
            }
          }
        }
        // Receiving JWT through URL query setup, it will be first prior
        if (token) {
          user.JWT = token;
        }
      }

      state.user = user;

      // Invalidate Session when there is a JWT Change
      if (state.user.JWT) {
        const lastJWT = localStorage.getItem("JWT");
        if (lastJWT) {
          const lastJWTPayloadRaw = jwt.decode(lastJWT);
          const currentJWTPayloadRaw = jwt.decode(state.user.JWT);
          const lastJWTPayload = parseJWTPayload(lastJWTPayloadRaw);
          const currentJWTPayload = parseJWTPayload(currentJWTPayloadRaw);

          const currentJWTIsNotEqualToPreviousJWT =
            JSON.stringify(lastJWTPayload) !== JSON.stringify(currentJWTPayload);

          if (currentJWTIsNotEqualToPreviousJWT) {
            dispatch("LOGOUT");
          }
        }
        setTokenToAxiosAuthorizationHeader(state.user.JWT);
        localStorage.setItem("JWT", state.user.JWT);
      }
    }
  },

  async FETCH_ENDPOINT({ state, dispatch }) {
    if (!window.kr_settings) {
      return;
    }
    const { server, bot, user, isPreview } = window.kr_settings;

    state.isPreview = isPreview || false; // Default to be false

    // to handle if error happened while fetching jwt, webchat should not start
    try {
      await dispatch("FETCH_JWT", { user });
    } catch (err) {
      return null;
    }

    if (server) {
      state.server = server;

      if (bot) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      return dispatch("FETCH_CONFIG");
    }
  },
  TOGGLE_ZENDESK_CHAT(store, toggle) {
    if (toggle) {
      window.$zopim.livechat.window.show();
    } else {
      window.$zopim.livechat.window.hide();
    }
  },
  RELOAD({ state, commit, dispatch }, settings) {
    if (window.kr_settings) {
      try {
        if (!_.isEmpty(settings?.apps)) {
          const apps = JSON.parse(decodeURIComponent(window.atob(settings.apps)));
          Object.keys(apps).forEach((key) => {
            if (apps[key] === "") {
              delete apps[key];
            }
          });
          Object.assign(settings, { apps }, window.kr_settings);
        }
        if (settings?.hasTnc === true) {
          settings.showTextbar = localStorage.getItem("showTextbar");
        } else {
          settings.showTextbar = true;
        }
        if (settings.cobrowse?.enabled) {
          // make CobrowseIO available in window
          initCobrowse(window, "CobrowseIO");
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    } else {
      Object.assign(settings, {
        showTextbar: localStorage.getItem("showTextbar"),
      });
    }

    commit("MERGE_SETTINGS", settings);
    if (settings && settings.openAutomatically === true) {
      const lastOpenStatus = localStorage.getItem("keyreply-lastOpenStatus");
      if (lastOpenStatus == "true" && !state.webchatUsersIsAtMaxLimit) {
        commit("OPEN_CHAT_WINDOW");
        dispatch("START");
      }
    }

    // Use Sentry Flag to control sending of error logs
    if (settings && settings.sentry) {
      Sentry.init({
        dsn: "https://5186808e218b4da59e868263ccccff81@sentry.io/242275",
        integrations: [
          new Integrations.Vue({
            Vue,
          }),
          new TracingIntegrations.BrowserTracing(),
        ],
        tracesSampleRate: 0.01,
        release: releaseId(),
        environment: process.env.NODE_ENV,
        beforeSend(event) {
          const exception = event.exception;
          // Check if it is an exception, if so, show the report dialog
          if (exception) {
            const message = _.get(exception, "values[0].value", "");

            // Only check for exception message as string,
            // if message is object (due to wrong error capturing), simply ignore the event
            if (typeof message !== "string") {
              return null;
            }

            const isHttpPermissionError = message.match(/403|401/i);
            const is4XXError = isHttpPermissionError;
            const isNetworkError = message.match(
              /network error|timeout of 0ms exceeded|xhr post error|xhr poll error/i
            );

            if (is4XXError || isNetworkError) {
              return null;
            }
          }
          return event;
        },
      });
    }
  },
  TOGGLE_CHAT_WINDOW(context, isSDK) {
    let url = "";
    if (context.state.chatWindow) {
      context.dispatch("CLOSE_CHAT_WINDOW");
      url = "keyreplysdk://?alert=close";
    } else {
      context.dispatch("OPEN_CHAT_WINDOW");
      url = "keyreplysdk://?alert=open";
    }

    if (isSDK) {
      window.location.href = url;
    }
  },
  OPEN_CHAT_WINDOW(context) {
    localStorage.setItem("keyreply-lastOpenStatus", "true");
    context.commit("OPEN_CHAT_WINDOW");
  },
  CLOSE_CHAT_WINDOW(context) {
    localStorage.setItem("keyreply-lastOpenStatus", "false");
    context.commit("CLOSE_CHAT_WINDOW");
  },
  TOGGLE_FACEBOOK_CHAT_WINDOW(context) {
    if (context.state.facebookChatWindow) {
      context.dispatch("CLOSE_FACEBOOK_CHAT_WINDOW");
    } else {
      context.dispatch("OPEN_FACEBOOK_CHAT_WINDOW");
    }
  },
  /* Being used by server action */
  START_LIVECHAT({ state, commit }) {
    commit("TOGGLE_LIVECHAT_BANNER", true);
  },
  /* Being used by server action */
  STOP_LIVECHAT({ state, commit }) {
    commit("TOGGLE_LIVECHAT_BANNER", false);
    commit("SET_COBROWSE_SESSION", false);
  },
  /* Being used by server action */
  TOGGLE_TYPING_INDICATOR({ state, commit }, status) {
    const { flag, agentName } = status;

    if (!agentName) {
      commit("SET_LIVECHAT_AGENT_NAME", "Agent");
    } else if (agentName && agentName !== state.liveChatAgentName) {
      commit("SET_LIVECHAT_AGENT_NAME", agentName);
    }

    commit("TOGGLE_TYPING_INDICATOR", flag);
  },
  /* Being used by server action */
  async PING_SESSION({ state, dispatch, commit }, payload) {
    if (state.sessionExpired) {
      await dispatch("LOGIN");
    }
    localStorage.setItem("_krlastseen", dayjs().format());
  },
  async SEND_MESSAGE({ state, dispatch, commit }, payload) {
    const user = state.user;
    payload.isPreview = state.isPreview;
    payload.lastActivity = getLastActivityDate();
    await dispatch("PING_SESSION");
    // Message ID
    const id = cuid();
    payload.id = id;
    if (payload.text) {
      // 1 checkmark
      commit("SEND_MESSAGE", { text: payload.text, id });
    }
    try {
      const response = await axios.post(new URL("api/webchat", state.server).href, {
        sender: {
          id: state.uid,
          session_id: localStorage.getItem("_krsessionid"),
          appSource: user && user.source ? user.source : null,
          role: user && user.role ? user.role : null,
        },
        recipient: {
          id: state.page_id || location.hostname,
        },
        message: payload,
        session_length: state.settings.session_length,
        resume_session: state.settings.resume,
      });
      if (response.data[0]) {
        const { options, meta } = response.data[0];
        if (meta && meta.lang) {
          if (meta.lang !== "default") {
            commit("SET_STICKY_MENU_LANGUAGE", meta.lang);
          }
          commit("SET_CURRENT_LANGUAGE", meta.lang);
        }
        // if (options && options.nodeId) {
        //   const { nodeId } = options;
        //   if (!NOT_VALID_RESPONSE.includes(nodeId)) {
        //     const userIp = state.uid;
        //     const lastActivity = getLastActivityDate();
        //     return axios.post(new URL("api/webchat_livechat_input_intent", state.server).href, {
        //       nodeId,
        //       userIp,
        //       text: payload.text,
        //       lastActivity,
        //     });
        //   }
        // }
      }
      dispatch("UPDATE_MESSAGE_STATUS", {
        responseStatus: _.get(response, "status"),
        id,
        errorMessage: _.get(response, "data.message"),
      });

      const jwtExpiredAppFlow = _.get(response, "data.jwtExpiredAppFlow");
      if (jwtExpiredAppFlow) {
        const requestData = _.get(response, "data.requestData");
        commit("ADD_JWT_EXPIRED_REQUEST", requestData);
      }
    } catch (error) {
      commit("UPDATE_MESSAGE_STATUS", {
        id,
        sent: false,
        errorMessage: FAILED_MESSAGE_ERROR_TEXT,
      });
      throw error;
    }
  },

  async RESEND_MESSAGE({ state, dispatch, commit }, payload) {
    const user = state.user;
    const messageId = payload.id;
    payload.isPreview = state.isPreview;

    await dispatch("PING_SESSION");
    try {
      const response = await axios.post(new URL("api/webchat", state.server).href, {
        sender: {
          id: state.uid,
          session_id: localStorage.getItem("_krsessionid"),
          appSource: user && user.source ? user.source : null,
          role: user && user.role ? user.role : null,
        },
        recipient: {
          id: state.page_id || location.hostname,
        },
        message: payload,
        session_length: state.settings.session_length,
        resume_session: state.settings.resume,
      });

      dispatch("UPDATE_MESSAGE_STATUS", {
        responseStatus: _.get(response, "status"),
        id: messageId,
        errorMessage: _.get(response, "data.message"),
      });

      const jwtExpiredAppFlow = _.get(response, "data.jwtExpiredAppFlow");
      if (jwtExpiredAppFlow) {
        const requestData = _.get(response, "data.requestData");
        commit("ADD_JWT_EXPIRED_REQUEST", requestData);
      }
    } catch (error) {
      commit("UPDATE_MESSAGE_STATUS", {
        id: messageId,
        sent: false,
        errorMessage: FAILED_MESSAGE_ERROR_TEXT,
      });
      throw error;
    }
  },

  // TODO: Merge action with SEND_MESSAGE
  async SEND_POSTBACK(
    { state, getters, dispatch, commit },
    { event, data, text, options = {}, trendingText }
  ) {
    if (!getters.isSendingPostback) {
      commit("SET_SENDING_POSTBACK", true);
      await dispatch("PING_SESSION");

      const user = state.user;
      const isPreview = state.isPreview;

      // Message ID
      const id = cuid();
      const feedbackCollection = data?.options?.feedbackCollection || false;
      text = text ? text : trendingText;
      if (text && !feedbackCollection) {
        // 1 checkmark
        commit("SEND_MESSAGE", { type: "postback", text, id, trendingText });
      }

      const response = await axios.post(new URL("api/webchat", state.server).href, {
        id,
        sender: {
          id: state.uid,
          session_id: localStorage.getItem("_krsessionid"),
          appSource: user && user.source ? user.source : null,
          role: user && user.role ? user.role : null,
        },
        recipient: {
          id: state.page_id || location.hostname,
        },
        postback: {
          event,
          data,
          text,
          isPreview,
          options,
        },
        session_length: state.settings.session_length,
        resume_session: state.settings.resume,
      });
      commit("SET_SENDING_POSTBACK", false);
      const jwtExpiredAppFlow = _.get(response, "data.jwtExpiredAppFlow");
      if (jwtExpiredAppFlow) {
        const requestData = _.get(response, "data.requestData");
        commit("ADD_JWT_EXPIRED_REQUEST", requestData);
      }
    }
  },
  CLOSE_WEBVIEW({ dispatch }) {
    dispatch("SET_WEBVIEWURL", "");
    dispatch("SET_SHOW_WEBVIEW", false);
  },
  SET_WEBVIEWURL({ commit }, url: string) {
    commit("SET_WEBVIEWURL", url);
  },
  SET_SHOW_WEBVIEW({ commit }, payload: boolean) {
    commit("SET_SHOW_WEBVIEW", payload);
  },
  SET_RETURN_TO_CHAT({ commit }, returnToChatButton: boolean) {
    commit("SET_RETURN_TO_CHAT", returnToChatButton);
  },
  REQUEST_SAS(store, { filenames }) {
    // Sanitize filename to remove special chars
    const sanitizedFilenames = filenames.map((filename: string) => {
      const ext = _.last(filename.split("."));
      const name = filename.substr(0, filename.lastIndexOf("."));
      const sanitizedName = name.replace(/\W+/g, "-");
      return [sanitizedName, ext].join(".");
    });

    return axios.post(new URL("api/webchat_request_upload", store.state.server).href, {
      filenames: sanitizedFilenames,
    });
  },

  async UPLOAD_FILE({ dispatch, state }, { files, vm }) {
    const FILEUPLOAD_URL = new URL("api/webchat_file_upload", state.server).href;
    const formData = new FormData();

    const filenames = _.map(files, (file, index) => {
      // _vm is hidden type
      const formName = vm.$device.ie ? `files[${index}]` : "file";
      formData.append(formName, file, file.name);
      return file.name;
    });

    switch (state.settings.fileUploadStorageType) {
      case "azure":
        // eslint-disable-next-line no-case-declarations
        const { data: sasUrls } = await dispatch("REQUEST_SAS", { filenames });

        if (!vm.$device.ie) {
          // Normal Flow to upload direct to Azure Storage
          const uploads = Array.from(files).map((file: any, index) => {
            const azure = axios.create({
              baseURL: sasUrls[index],
              headers: {
                "content-type": file.type,
                "x-ms-blob-type": "BlockBlob",
                "x-ms-meta-owner": state.uid,
              },
            });

            // Why?
            delete azure.defaults.headers.common["Authorization"];
            return azure.put(sasUrls[index], file);
          });

          await Promise.all(uploads); // Upload
        } else {
          // Special handling for IE for CORS issue
          formData.append("sasUrls", JSON.stringify(sasUrls));
          await axios.post(FILEUPLOAD_URL, formData); // Upload
        }

        // Remove SAS token from URL
        // Reference: https://docs.microsoft.com/en-us/azure/storage/common/media/storage-sas-overview/sas-storage-uri.png
        // eslint-disable-next-line no-case-declarations
        const fileUrls = sasUrls.map((url: string) => _.first(url.split("?")));

        await dispatch("SEND_FILE", fileUrls);

        break;
      case "ceph":
        // eslint-disable-next-line no-case-declarations
        const { data: cephUrls } = await axios.post(FILEUPLOAD_URL, formData);
        await dispatch("SEND_FILE", cephUrls);
        break;

      default:
        break;
    }

    await dispatch("UPLOAD_FILE_TO_THIRD_PARTIES", formData);
  },
  async UPLOAD_FILE_TO_THIRD_PARTIES({ state }, data) {
    const FILEUPLOAD_URL = new URL(
      `api/webchat_file_upload_to_third_parties?requesterId=${state.uid}&page_id=${
        state.page_id || location.hostname
      }`,
      state.server
    ).href;
    await axios.post(FILEUPLOAD_URL, data);
  },
  async SEND_FILE({ state, dispatch, commit }, filesUrls: string[]) {
    const imageRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)/i;
    const endpoint = new URL("api/webchat", state.server).href;

    filesUrls = _.compact(filesUrls);

    const payloads = _.reduce(
      filesUrls,
      function ({ imageUrls, nonImageUrls }, url) {
        if (imageRegex.test(url)) {
          imageUrls.push(url);
        } else {
          nonImageUrls.push(url);
        }

        return { imageUrls, nonImageUrls };
      },
      {
        imageUrls: <string[]>[],
        nonImageUrls: <string[]>[],
      }
    );

    const promises = _.map(payloads, (urls, key) => {
      if (_.isEmpty(urls)) {
        return;
      }

      const payload: any = {
        isPreview: state.isPreview,
      };

      if (key === "imageUrls") {
        payload.images = urls;
      } else {
        payload.files = urls;
      }

      const id = cuid();

      commit("SEND_FILE", { content: payload, id });
      try {
        const response = axios.post(endpoint, {
          id,
          sender: {
            id: state.uid,
            appSource: state.user?.source,
            role: state.user?.role,
            session_id: localStorage.getItem("_krsessionid"),
          },
          recipient: { id: state.page_id || location.hostname },
          message: payload,
          session_length: state.settings.session_length,
          resume_session: state.settings.resume,
        });

        dispatch("UPDATE_MESSAGE_STATUS", {
          responseStatus: _.get(response, "status"),
          id,
          errorMessage: _.get(response, "data.message"),
        });

        return response;
      } catch (error) {
        commit("UPDATE_MESSAGE_STATUS", {
          id,
          sent: false,
          errorMessage: FAILED_MESSAGE_ERROR_TEXT,
        });
        throw error;
      }
    });

    return Promise.all(_.compact(promises));
  },

  async RESEND_FILE({ state, dispatch, commit }, { filesUrls, id, timestamp }) {
    const imageRegex = /\.(gif|jpe?g|tiff?|png|webp|bmp)/i;
    const endpoint = new URL("api/webchat", state.server).href;

    filesUrls = _.compact(filesUrls);

    const payloads = _.reduce(
      filesUrls,
      function ({ imageUrls, nonImageUrls }, url) {
        if (imageRegex.test(url)) {
          imageUrls.push(url);
        } else {
          nonImageUrls.push(url);
        }

        return { imageUrls, nonImageUrls };
      },
      {
        imageUrls: <string[]>[],
        nonImageUrls: <string[]>[],
      }
    );

    const promises = _.map(payloads, (urls, key) => {
      if (_.isEmpty(urls)) {
        return;
      }

      const payload: any = {
        isPreview: state.isPreview,
      };

      if (key === "imageUrls") {
        payload.images = urls;
      } else {
        payload.files = urls;
      }

      payload.timestamp = timestamp;

      try {
        //FIXME: duplicated with SEND_FILE
        const response = axios.post(endpoint, {
          id,
          sender: {
            id: state.uid,
            appSource: state.user?.source,
            role: state.user?.role,
            session_id: localStorage.getItem("_krsessionid"),
          },
          recipient: { id: state.page_id || location.hostname },
          message: payload,
          session_length: state.settings.session_length,
          resume_session: state.settings.resume,
        });

        dispatch("UPDATE_MESSAGE_STATUS", {
          responseStatus: _.get(response, "status"),
          id,
          errorMessage: _.get(response, "data.message"),
        });
        return response;
      } catch (error) {
        commit("UPDATE_MESSAGE_STATUS", {
          id,
          sent: false,
          errorMessage: FAILED_MESSAGE_ERROR_TEXT,
        });
        throw error;
      }
    });

    return Promise.all(_.compact(promises));
  },
  UPDATE_MESSAGE_STATUS({ commit }, { responseStatus, id, errorMessage }) {
    if (responseStatus === 200) {
      commit("UPDATE_MESSAGE_STATUS", { id, sent: true });
    } else {
      commit("UPDATE_MESSAGE_STATUS", {
        id,
        sent: false,
        errorMessage: errorMessage || FAILED_MESSAGE_ERROR_TEXT,
      });
    }
  },
  CLOSE_DIALOG({ commit }) {
    commit("CLOSE_DIALOG");
  },
  EMAIL_HISTORY({ state }, payload) {
    const endpoint = new URL("api/webchat_email_history", state.server).href;

    return axios.post(endpoint, {
      payload,
    });
  },
  SET_EMAIL_WHITELIST({ commit }, payload) {
    commit("SET_EMAIL_WHITELIST", payload);
  },
  FETCH_FILE_URL: async ({ state }, fileUrl) => {
    const url = _.get(fileUrl, "url", fileUrl);
    const urlObj = new URL(url);
    const blobName = urlObj.pathname;

    const requestFileUrl = new URL(
      `api/webchat_request_file?requesterId=${state.uid}&page_id=${
        state.page_id || location.hostname
      }&blobName=${blobName}`,
      state.server
    );
    return axios.get(requestFileUrl.href);
  },
  UPDATE_AGENT_TYPING_INDICATOR: async ({ state }, status) => {
    return axios.post(new URL("api/webchat_livechat_update_typing_indicator", state.server).href, {
      partition_key: state.page_id || location.hostname,
      user_id: state.uid,
      status,
    });
  },
  REMOTE_CLEAR: async ({ state, dispatch }) => {
    await dispatch("LOGOUT");
    await dispatch("STOP_LIVECHAT");
    await dispatch("REGISTER");
    await dispatch("LOGIN");
    await dispatch("LISTEN");
    await dispatch("SEND_START_MESSAGE");
  },
  SET_DISPLAY_LAUNCHER: async ({ state, commit }, status: boolean) => {
    commit("SET_DISPLAY_LAUNCHER", status);
  },
  DISABLE_TEXT_INPUT: async ({ state, commit }, status: boolean) => {
    commit("DISABLE_TEXT_INPUT", status);
  },
  GET_LIVECHAT_STATUS({ state }) {
    return _.get(state, "settings.liveChatStatus", false);
  },
  SET_NATIVE_ACTION({ commit }, fn) {
    commit("SET_NATIVE_ACTION", fn);
  },
  SET_CUSTOM_SUGGESTIONS({ commit }, payload) {
    commit("SET_CUSTOM_SUGGESTIONS", payload);
  },

  async SEND_PRECHATFORM_DETAILS({ state }, form) {
    const result = await axios.post(new URL("api/prechatform_details", state.server).href, {
      sender: {
        id: state.uid,
        session_id: localStorage.getItem("_krsessionid"),
      },
      recipient: {
        id: state.page_id || location.hostname,
      },
      form,
    });
    const stateSet = _.get(result, "data", false);
    return stateSet;
  },

  async GET_PRECHATFORM_SETTINGS({ state }) {
    const result = await axios.get(new URL("api/prechatform_settings", state.server).href);

    const prechatFormSettings = _.get(result, "data.prechatFormSettings", {});
    return prechatFormSettings;
  },

  async SEND_POSTCHATFORM_DETAILS({ state }, form) {
    const result = await axios.post(new URL("api/postchatform_details", state.server).href, {
      sender: {
        id: state.uid,
        session_id: localStorage.getItem("_krsessionid"),
      },
      recipient: {
        id: state.page_id || location.hostname,
      },
      form,
    });
    const stateSet = _.get(result, "data", false);
    return stateSet;
  },

  async GET_POSTCHATFORM_SETTINGS({ state }) {
    const result = await axios.get(new URL("api/postchatform_settings", state.server).href);

    const postchatFormSettings = _.get(result, "data.postchatFormSettings", {});
    return postchatFormSettings;
  },

  FETCH_COBROWSE_STATUS({ state, dispatch, commit }) {
    return axios
      .post(new URL("api/webchat_cobrowse_check", state.server).href, {
        sender: {
          id: state.uid,
        },
        recipient: {
          id: state.page_id || location.hostname,
        },
      })
      .then((result) => {
        const inCobrowseSession = _.get(result, "data.inCobrowseSession");
        // auto reconnect to cobrowse session
        if (inCobrowseSession) {
          dispatch("START_COBROWSE");
        }
      });
  },

  START_COBROWSE({ state, commit }) {
    commit("SET_COBROWSE_SESSION", true);
  },

  STOP_COBROWSE({ state, commit }) {
    // tirggered when agent close cobrowse drawer
    if (!state.promptedCobrowseEnded) {
      MessageBox.alert("Cobrowsing session has ended", "Support Ended", {
        confirmButtonText: "Close",
      });
      state.promptedCobrowseEnded = true;
      commit("SET_COBROWSE_SESSION", false);
      setTimeout(() => {
        state.promptedCobrowseEnded = false;
      });
    }
  },
};
