<template>
  <div>
    <input
      id="fileUploader"
      ref="fileUploader"
      type="file"
      style="display: none"
      accept="image/*, .xls, .xlsx, .doc, .docx, .dotx, application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      @change="onChangeUpload"
    />

    <input
      id="imageUploader"
      ref="imageUploader"
      type="file"
      style="display: none"
      accept="image/*"
      @change="onChangeUpload"
    />

    <input
      id="documentUploader"
      ref="documentUploader"
      type="file"
      style="display: none"
      accept=".xls, .xlsx, .doc, .docx, .dotx, application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      @change="onChangeUpload"
    />

    <el-dialog
      width="550px"
      title="File Upload"
      :visible.sync="dialogVisible"
      :modal="false"
      :show-close="false"
      @closed="onDialogClosed"
    >
      <div v-if="settings.fileUploadDisclaimer" style="font-family: Arial, sans-serif">
        <span style="font-weight: bold">Disclaimer:</span>
        <br />
        <span v-html="$options.filters.markHTML(settings.fileUploadDisclaimer)"></span>
      </div>

      <br />
      <File
        v-for="(value, index) in confirmationFiles"
        v-model="confirmationFiles[index]"
        :file="pendingUploadFiles[index]"
        :preview="confirmationFilesPreview[index]"
        :key="index"
        @update-is-busy="updateIsBusy"
        @update-antivirus-failed="updateAntivirusFailed"
        @update-is-file-type-invalid="updateIsFileTypeInvalid"
        ref="uploadedFiles"
      />
      <span slot="footer" class="dialog-footer">
        <el-button :disabled="isBusy" @click="dialogVisible = false">Cancel</el-button>
        <el-button
          :loading="isBusy"
          :disabled="isInvalid"
          type="primary"
          @click="confirmFileUpload"
        >
          Confirm
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { transformFileName } from "@/helpers/validateFile";
import _ from "lodash-es";
import File from "./File";
import * as Sentry from "@sentry/browser";

export default {
  name: "FileUploader",
  components: {
    File,
  },
  data() {
    return {
      dialogVisible: false,
      confirmationFiles: [],
      confirmationFilesPreview: [],
      pendingUploadFiles: [],
      isBusy: false,
      antivirusFailed: true,
      isFileTypeInvalid: true,
    };
  },
  computed: {
    /**
     * @description All webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },

    canUpload() {
      // FIXME: ??
      const invalidComponent = this.$refs.uploadedFiles.find(
        (uploadedFileComponent) => !uploadedFileComponent.isValid
      );
      return !invalidComponent;
    },
    isInvalid() {
      return _.isEmpty(this.pendingUploadFiles) || this.antivirusFailed || this.isFileTypeInvalid;
    },
  },
  methods: {
    updateTypingIndicator: _.debounce(
      function (isTyping) {
        this.$store.dispatch("UPDATE_AGENT_TYPING_INDICATOR", isTyping);
      },
      500,
      { leading: true }
    ),
    updateIsBusy(value) {
      this.isBusy = value;
    },
    updateAntivirusFailed(value) {
      this.antivirusFailed = value;
    },
    updateIsFileTypeInvalid(value) {
      this.isFileTypeInvalid = value;
    },
    onDialogClosed() {
      this.dialogVisible = false;
      this.confirmationFiles = [];
      this.confirmationFilesPreview = [];
      this.pendingUploadFiles = [];
      this.isBusy = false;
    },
    onUpload(type) {
      switch (type) {
        case "image":
          this.$refs.imageUploader.value = null;
          this.$refs.imageUploader.click();
          break;
        case "document":
          this.$refs.documentUploader.value = null;
          this.$refs.documentUploader.click();
          break;
        case "file":
          this.$refs.fileUploader.value = null;
          this.$refs.fileUploader.click();
          break;
        default:
        //
      }
    },
    onChangeUpload(event) {
      this.dialogVisible = true;
      const files = event.target.files;

      this.confirmationFilesPreview = _.map(files, (item, index) => {
        return URL.createObjectURL(item);
      });

      this.confirmationFiles = _.map(files, (item, index) => {
        return transformFileName(item.name);
      });

      this.pendingUploadFiles = files;
    },
    async confirmFileUpload() {
      if (!this.canUpload) {
        return;
      }

      this.isBusy = true;
      this.updateTypingIndicator(true);

      try {
        await this.$store.dispatch("UPLOAD_FILE", {
          files: this.pendingUploadFiles,
          vm: this,
        });
      } catch (err) {
        Sentry.captureException(err);
        this.$notify({
          title: "File upload failed",
          type: "error",
        });
      } finally {
        this.isBusy = false;
        this.dialogVisible = false;
        this.updateTypingIndicator(false);
      }
    },
  },
  mounted() {
    this.$root.$on("upload", (event) => {
      this.onUpload("file");
    });
  },
};
</script>

<style></style>
