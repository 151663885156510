<template>
  <swiper
    ref="kr-carousel"
    style="margin: 10px 0; padding-bottom: 10px"
    :style="{
      overflow: isMobileSafari ? 'auto' : 'hidden',
    }"
    :options="{ ...swiperOptions, allowTouchMove }"
    @slideChange="handleSlideChange"
  >
    <!-- slides -->
    <swiper-slide
      v-for="(item, index) in message.data.content"
      :key="index"
      style="max-width: 65%; height: auto"
    >
      <el-card
        style="height: fit-content"
        class="message-card-item"
        :style="isUiBetaEnabled && backgroundStyle"
        :body-style="{
          padding: '0',
          height: 'fit-content',
          display: itemImage(item) && item.text ? 'flex' : 'block',
          flexDirection: 'column',
        }"
      >
        <div v-if="itemImage(item) && item.text" style="background: #fff">
          <div
            v-if="itemImage(item)"
            :class="!settings.disableLightBoxImage ? 'image has-cursor-pointer' : 'image'"
            style="background-position: center; background-repeat: no-repeat; height: 160px"
            :style="imageStyle(item, itemImage(item))"
            @click="setLightBoxImage(item)"
          />
        </div>

        <div v-else-if="itemImage(item)">
          <a
            :class="!settings.disableLightBoxImage ? 'has-cursor-pointer' : ''"
            @click="setLightBoxImage(item)"
          >
            <img style="width: 100%" :src="itemImage(item)" />
          </a>
        </div>

        <div
          class="message-card-content"
          style="display: flex; flex-direction: column; height: 100%"
        >
          <div v-if="item.text || item.subtext" style="padding: 8px">
            <div style="line-height: 18px">
              <span
                :style="{
                  fontFamily: settings.font,
                  color: isUiBetaEnabled && titleStyle.color,
                }"
                style="font-weight: 600; font-size: 16px"
                v-text="item.text"
                class="message-card-title"
              />
            </div>
            <div style="margin: 4px 0; line-height: 16px">
              <small
                :style="{
                  fontFamily: settings.font,
                }"
                class="subtext"
                style="font-size: 14px, color: #333"
                >{{ item.subtext }}</small
              >
            </div>
          </div>

          <el-row type="text" style="flex-direction: column; padding: 0 10px">
            <el-button
              class="message-card-button"
              :class="{ 'blue-button': buttonOutline === 'primary' }"
              v-for="(button, buttonIndex) in item.buttons"
              v-text="button.text"
              :size="isUiBetaEnabled ? 'medium' : 'small'"
              target="_blank"
              style="width: 100%; margin: 0 0 10px 0; white-space: normal"
              :style="{
                fontFamily: settings.font,
              }"
              :key="buttonIndex"
              :plain="true"
              :type="buttonOutline"
              :disabled="isDisabledButton(button)"
              @click.stop.prevent="$emit('sendPostback', button)"
            />
          </el-row>
        </div>
        <div
          class="navigation"
          v-if="totalSlides > 1 && isUiBetaEnabled"
          :class="{ disabled: current !== index }"
        >
          <button
            v-if="current > 0"
            class="navigation-prev"
            :style="{ ...iconStyle, fontFamily: settings.font }"
            @click="prev"
          >
            <i :class="navigationIconClasses.prev"></i>
          </button>
          <button
            class="navigation-next"
            :style="{ ...iconStyle, fontFamily: settings.font }"
            @click="next"
            v-if="current < totalSlides - 1"
          >
            <i :class="navigationIconClasses.next"></i>
          </button>
        </div>
      </el-card>
    </swiper-slide>

    <button
      v-if="message.data.content.length > 1 && !isUiBetaEnabled"
      class="el-carousel__arrow el-carousel__arrow--left"
      style="background: rgba(31, 45, 61, 0.5); min-width: auto"
      :style="{
        fontFamily: settings.font,
      }"
      slot="button-prev"
      @click="prev"
    >
      <i class="el-icon-arrow-left" />
    </button>
    <button
      v-if="message.data.content.length > 1 && !isUiBetaEnabled"
      class="el-carousel__arrow el-carousel__arrow--right"
      style="background: rgba(31, 45, 61, 0.5); min-width: auto"
      :style="{
        fontFamily: settings.font,
      }"
      slot="button-next"
      @click="next"
    >
      <i class="el-icon-arrow-right" />
    </button>

    <div class="pagination" slot="pagination" v-if="totalSlides > 1 && isUiBetaEnabled">
      <span
        class="dot"
        v-for="(item, index) in message.data.content"
        :key="index"
        :class="{ active: current === index }"
        @click="handleNavigateSlide(index)"
      />
    </div>
  </swiper>
</template>

<script>
import Vue from "vue";
import {
  Swiper as SwiperClass,
  // Pagination,
  // Mousewheel,
  // Autoplay,
} from "swiper/core";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import _ from "lodash-es";
import isDisabledButton from "@/helpers/isDisabledButton";
import { mapGetters } from "vuex";

const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default {
  props: [
    "message",
    "buttonOutline",
    "shouldDisableIfInLivechat",
    "backgroundStyle",
    "titleStyle",
    "iconStyle",
  ],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      current: 0,
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: true,
        spaceBetween: 20,
        pagination: {
          el: ".pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".el-carousel__arrow--right",
          prevEl: ".el-carousel__arrow--left",
        },
        direction: "horizontal",
      },
      contentUrls: [],
    };
  },

  computed: {
    ...mapGetters(["isRtlModeEnabled", "isUiBetaEnabled"]),
    /**
     * @description Most of the webchat config
     * @return {any}
     */
    settings() {
      return this.$store.getters.settings;
    },
    isMobileSafari() {
      let ua = window.navigator.userAgent;
      return /(iPad|iPhone|iPod).*WebKit/.test(ua) && !/(CriOS|OPiOS)/.test(ua);
    },
    swiper() {
      return this.$refs["kr-carousel"].$swiper;
    },
    totalSlides() {
      return this.message.data.content.length;
    },
    allowTouchMove() {
      return this.totalSlides > 1;
    },
    navigationIconClasses() {
      return {
        next: this.isRtlModeEnabled ? "el-icon-arrow-left" : "el-icon-arrow-right",
        prev: this.isRtlModeEnabled ? "el-icon-arrow-right" : "el-icon-arrow-left",
      };
    },
  },
  methods: {
    setLightBoxImage(item) {
      if (this.settings.disableLightBoxImage) {
        return;
      }
      this.$emit("setLightBoxImage", this.itemImage(item));
    },
    itemImage(item) {
      const image = item.image || _.get(item, "images[0]");
      return image;
    },

    /**
     * @description Go to next slide
     * @return {void}
     */
    next() {
      this.swiper.slideNext();
    },

    /**
     * @description Go to previous slide
     * @return {void}
     */
    prev() {
      this.swiper.slidePrev();
    },

    imageStyle(item, url) {
      return {
        backgroundSize: _.get(item, "options.padded") ? "contain" : "cover",
        backgroundImage: `url("${url}")`,
      };
    },

    isDisabledButton(button) {
      return isDisabledButton(this, button);
    },

    handleNavigateSlide(index) {
      this.swiper.slideTo(index);
    },

    handleSlideChange() {
      this.current = this.swiper.activeIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~swiper/swiper-bundle.css";
>>> .swiper-wrapper {
  height: auto;
  align-items: stretch;
}
</style>

<style lang="scss" scoped>
.ui-beta {
  .swiper-container {
    overflow: visible !important;
  }

  .swiper-slide {
    max-width: 75% !important;
  }

  .message-card-item {
    border-radius: 16px;
    overflow: hidden;
    // height: 100% !important;
  }

  .message-card-content {
    text-align: left;
  }

  .message-card-button {
    display: inline-block;
    border-radius: 8px;
    font-size: 12px;

    &.blue-button {
      background: #fff;
      border: 1px solid #4e6cce;
      color: #4e6cce;

      &:hover,
      &:focus,
      &:active {
        background: #4e6cce !important;
        color: #fff;
      }
    }
  }

  .message-card-title {
    display: inline-block;
    font-weight: 400;
    font-size: 20px;
    color: #4e6cce;
    margin-bottom: 16px;
    text-align: left;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    width: 100%;

    .dot {
      width: 4px;
      height: 4px;
      background: #999;
      border-radius: 200px;
      cursor: pointer;
      transition: all 0.5s ease-in-out;

      &.active {
        background: #333;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 8px;

    &.disabled {
      opacity: 0;
    }

    &-next,
    &-prev {
      border-radius: 100%;
      background: #4e6cce;
      width: 25px;
      height: 25px;
      border: 0;
      cursor: pointer;
      color: #fff;
      font-size: 14px;
      min-width: 25px;
    }
  }
}

[dir="ltr"] .message-card-content {
  text-align: left;
  padding: 10px;
}

[dir="rtl"] .message-card-content {
  text-align: right;
}

[dir="ltr"] .message-card-button {
  margin-right: 8px !important;
}

[dir="rtl"] .message-card-button {
  margin-left: 8px !important;
}

[dir="ltr"] .ui-beta .pagination {
  .dot {
    margin-right: 3px;
  }
}

[dir="rtl"] .ui-beta .pagination {
  .dot {
    margin-left: 3px;
  }
}

[dir="ltr"] .ui-beta .navigation {
  &-next {
    margin-left: auto;
  }
}

[dir="rtl"] .ui-beta .navigation {
  &-next {
    margin-right: auto;
  }
}
</style>

<style lang="scss">
.ui-beta {
  .message-card-item .el-card__body {
    height: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  .swiper-wrapper {
    height: auto !important;
  }
}
</style>
