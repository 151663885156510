import _ from "lodash";
import FileType from "file-type";
import * as Sentry from "@sentry/browser";

export async function validateFiles(
  files: { name: string; size: number }[]
): Promise<{ isValid: boolean; errors: string[] }> {
  let overallErrors: string[] = [];
  const validation = [];
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const { isValid, errors } = await validateFile(file);
    if (errors.length > 0) {
      overallErrors = overallErrors.concat(errors);
    }
    validation.push(isValid);
  }

  const isValid = _.every(validation, (v) => !!v);

  return { isValid, errors: overallErrors };
}

export async function validateFile(file: {
  name: string;
  size: number;
}): Promise<{ isValid: boolean; errors: string[] }> {
  const errors = [];
  const fileName = file.name;

  let mimeTypeValid = true;
  try {
    const fr = new FileReader();
    fr.readAsArrayBuffer(file as any);
    const fileBuffer = await new Promise((resolve) => {
      fr.onloadend = (event) => {
        resolve(event?.target?.result);
      };
    });
    const filetype = await FileType.fromBuffer(fileBuffer as any);
    const mimeType = _.get(filetype, "mime", null);
    mimeTypeValid = hasWhitelistedMimeTypes(mimeType);
    if (!mimeTypeValid) {
      errors.push(
        `Invalid Mime Type: '${fileName}' can’t be uploaded as the mime type is not supported by the system.`
      );
    }
  } catch (error) {
    Sentry.captureException(error);
  }

  const whitelistedExtensionOnly = hasWhitelistedExtensions(fileName);
  if (!whitelistedExtensionOnly) {
    errors.push(
      `Invalid Action: '${fileName}' can’t be uploaded as the file type is not supported by the system.`
    );
  }

  const validFileSize = hasValidFileSize(file);
  if (!validFileSize) {
    errors.push(`Invalid Action: file size greater than 10mb`);
  }

  const isValid = mimeTypeValid && whitelistedExtensionOnly && validFileSize;
  return { isValid, errors };
}

export function hasWhitelistedExtensions(fileName: string): boolean {
  const allowedFileExtensions = [
    ".xls",
    ".xlsx",
    ".doc",
    ".docx",
    ".dotx",
    ".jpeg",
    ".jpg",
    ".png",
    ".pdf",
    ".svg",
    ".gif",
    ".json",
    ".jfif",
  ];

  const fileExtRegex = new RegExp(
    "(" + allowedFileExtensions.join("|").replace(/\./g, "\\.") + ")$",
    "i"
  );
  const isAllowed = fileExtRegex.test(fileName);

  return isAllowed;
}

export function hasWhitelistedMimeTypes(mimeType: string | null): boolean {
  const allowedMimeTypes = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "image/jpeg",
    "image/png",
    "application/pdf",
    "image/svg+xml",
    "image/gif",
    "application/json",
  ];
  return !mimeType ? false : allowedMimeTypes.includes(mimeType);
}

export function hasSingleExtension(fileName: string): boolean {
  const ext = fileName.split(".");
  const onlyOneExt = ext.length === 2;
  return onlyOneExt;
}

export function noSpecialCharacter(fileName: string): boolean {
  if (!fileName) {
    return true;
  }

  const regex = /[`~!@#$%^&*()|+=?;:'",<>{}\\]/gi;
  const haveSpecialCharacter = regex.test(fileName);

  return !haveSpecialCharacter;
}

export function hasValidFileSize(file: { size: number }, tenMbLimit = 10240): boolean {
  const fileSize = file.size;
  const fileSizeInKB = Math.round(fileSize / 1024);

  const fileLessThan10Mb = fileSizeInKB <= tenMbLimit;
  return fileLessThan10Mb;
}

export function transformFileName(originalFileName: string) {
  let fileExtension = "";
  let fileName = originalFileName;
  const lastIndexOfDot = originalFileName.lastIndexOf(".");

  if (lastIndexOfDot >= 0) {
    fileExtension = originalFileName.substring(lastIndexOfDot + 1, originalFileName.length);
    fileName = originalFileName.substring(0, lastIndexOfDot);
  }
  const newFileName = fileName.replace(/[`~!@#$%^&*()|+=?;:'",<>.\{\}\[\]\\\/]/gi, "-");
  return `${newFileName}.${fileExtension}`;
}
